// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
body {
  /** primary **/
  --ion-color-primary: #ffa938;
  --ion-color-primary-rgb: 255, 169, 56;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #e09531;
  --ion-color-primary-tint: #ffb24c;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-positive: #009e73;
  --ion-color-positive-rgb: 0, 158, 115;
  --ion-color-positive-contrast: #ffffff;
  --ion-color-positive-contrast-rgb: 255, 255, 255;
  --ion-color-positive-shade: #008b65;
  --ion-color-positive-tint: #1aa881;

  --ion-color-negative: #ea3943;
  --ion-color-negative-rgb: 234, 57, 67;
  --ion-color-negative-contrast: #ffffff;
  --ion-color-negative-contrast-rgb: 255, 255, 255;
  --ion-color-negative-shade: #ce323b;
  --ion-color-negative-tint: #ec4d56;

  --ion-item-border-color: rgba(var(--ion-color-medium-rgb), 0.3);
  --ion-background-secondary: #f9f9f9;

  --plyr-color-main: var(--ion-color-primary);
  --swiper-pagination-color: var(--ion-color-dark);
}

@media (prefers-color-scheme: dark) {

  /* Dark Theme (System) */

  body {
    --ion-color-primary: #ffa938;
    --ion-color-primary-rgb: 255, 169, 56;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #e09531;
    --ion-color-primary-tint: #ffb24c;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    --ion-background-secondary: #101010;

    /* Manual Light Theme */

    &.light {
      /** primary **/
      --ion-color-primary: #ffa938;
      --ion-color-primary-rgb: 255, 169, 56;
      --ion-color-primary-contrast: #ffffff;
      --ion-color-primary-contrast-rgb: 255, 255, 255;
      --ion-color-primary-shade: #e09531;
      --ion-color-primary-tint: #ffb24c;

      /** secondary **/
      --ion-color-secondary: #3dc2ff;
      --ion-color-secondary-rgb: 61, 194, 255;
      --ion-color-secondary-contrast: #ffffff;
      --ion-color-secondary-contrast-rgb: 255, 255, 255;
      --ion-color-secondary-shade: #36abe0;
      --ion-color-secondary-tint: #50c8ff;

      /** tertiary **/
      --ion-color-tertiary: #5260ff;
      --ion-color-tertiary-rgb: 82, 96, 255;
      --ion-color-tertiary-contrast: #ffffff;
      --ion-color-tertiary-contrast-rgb: 255, 255, 255;
      --ion-color-tertiary-shade: #4854e0;
      --ion-color-tertiary-tint: #6370ff;

      /** success **/
      --ion-color-success: #2dd36f;
      --ion-color-success-rgb: 45, 211, 111;
      --ion-color-success-contrast: #ffffff;
      --ion-color-success-contrast-rgb: 255, 255, 255;
      --ion-color-success-shade: #28ba62;
      --ion-color-success-tint: #42d77d;

      /** warning **/
      --ion-color-warning: #ffc409;
      --ion-color-warning-rgb: 255, 196, 9;
      --ion-color-warning-contrast: #000000;
      --ion-color-warning-contrast-rgb: 0, 0, 0;
      --ion-color-warning-shade: #e0ac08;
      --ion-color-warning-tint: #ffca22;

      /** danger **/
      --ion-color-danger: #eb445a;
      --ion-color-danger-rgb: 235, 68, 90;
      --ion-color-danger-contrast: #ffffff;
      --ion-color-danger-contrast-rgb: 255, 255, 255;
      --ion-color-danger-shade: #cf3c4f;
      --ion-color-danger-tint: #ed576b;

      /** dark **/
      --ion-color-dark: #222428;
      --ion-color-dark-rgb: 34, 36, 40;
      --ion-color-dark-contrast: #ffffff;
      --ion-color-dark-contrast-rgb: 255, 255, 255;
      --ion-color-dark-shade: #1e2023;
      --ion-color-dark-tint: #383a3e;

      /** medium **/
      --ion-color-medium: #92949c;
      --ion-color-medium-rgb: 146, 148, 156;
      --ion-color-medium-contrast: #ffffff;
      --ion-color-medium-contrast-rgb: 255, 255, 255;
      --ion-color-medium-shade: #808289;
      --ion-color-medium-tint: #9d9fa6;

      /** light **/
      --ion-color-light: #f4f5f8;
      --ion-color-light-rgb: 244, 245, 248;
      --ion-color-light-contrast: #000000;
      --ion-color-light-contrast-rgb: 0, 0, 0;
      --ion-color-light-shade: #d7d8da;
      --ion-color-light-tint: #f5f6f9;

      --ion-color-positive: #009e73;
      --ion-color-positive-rgb: 0, 158, 115;
      --ion-color-positive-contrast: #ffffff;
      --ion-color-positive-contrast-rgb: 255, 255, 255;
      --ion-color-positive-shade: #008b65;
      --ion-color-positive-tint: #1aa881;

      --ion-color-negative: #ea3943;
      --ion-color-negative-rgb: 234, 57, 67;
      --ion-color-negative-contrast: #ffffff;
      --ion-color-negative-contrast-rgb: 255, 255, 255;
      --ion-color-negative-shade: #ce323b;
      --ion-color-negative-tint: #ec4d56;

      --ion-background-secondary: #f9f9f9;
      --ion-item-border-color: rgba(var(--ion-color-medium-rgb), 0.3);

      // Invert figures added in WordPress/Datawrapper
      figure.wp-block-embed {
        filter: none !important;
      }

      figure.is-provider-twitter {
        filter: none !important;
      }
    }

    // auto theme switch on system dark mode
    &:not(.light):not(.dark) {
        // Invert figures added in WordPress/Datawrapper
        figure.wp-block-embed {
          filter: invert(1) hue-rotate(180deg);
        }
  
        figure.is-provider-twitter {
          filter: none !important;
        }
    }
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;

    &.light {
      --ion-background-color: #ffffff;
      --ion-background-color-rgb: unset;

      --ion-text-color: unset;
      --ion-text-color-rgb: unset;

      --ion-color-step-50: unset;
      --ion-color-step-100: unset;
      --ion-color-step-150: unset;
      --ion-color-step-200: unset;
      --ion-color-step-250: unset;
      --ion-color-step-300: unset;
      --ion-color-step-350: unset;
      --ion-color-step-400: unset;
      --ion-color-step-450: unset;
      --ion-color-step-500: unset;
      --ion-color-step-550: unset;
      --ion-color-step-600: unset;
      --ion-color-step-650: unset;
      --ion-color-step-700: unset;
      --ion-color-step-750: unset;
      --ion-color-step-800: unset;
      --ion-color-step-850: unset;
      --ion-color-step-900: unset;
      --ion-color-step-950: unset;

      --ion-item-background: unset;

      --ion-card-background: unset;
    }

    ion-modal {
      --ion-background-color: var(--ion-color-step-100);
      --ion-toolbar-background: var(--ion-color-step-150);
      --ion-toolbar-border-color: var(--ion-color-step-250);

      &.light {
        --ion-background-color: unset;
        --ion-toolbar-background: unset;
        --ion-toolbar-border-color: unset;
      }
    }
  }
}

/*
* Manual Dark Mode
*/

body.dark {
  --ion-color-primary: #ffa938;
  --ion-color-primary-rgb: 255, 169, 56;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e09531;
  --ion-color-primary-tint: #ffb24c;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;


  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #000000;
  --ion-card-background: #1c1c1d;
  --ion-background-secondary: #101010;

  ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  // Invert figures added in WordPress/Datawrapper
  figure.wp-block-embed {
    filter: invert(1) hue-rotate(180deg);
  }

  figure.is-provider-twitter {
    filter: none !important;
  }
}
